import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

export class AuthInfo {
    public access_token: null;
    public refresh_token: null;


    constructor(public $uid: string) {
    }

    isLoggedIn() {
        return !!this.$uid;
    }

    setToken(token) {
        this.access_token = token;
    }

    setRefresh(token) {
        this.refresh_token = token;
    }

    saveToStorage() {
        localStorage.setItem('access_token', this.access_token);
        localStorage.setItem('refresh_token', this.refresh_token);
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token');
    }

    getAccessToken() {
        return localStorage.getItem('access_token');
    }
}

@Injectable()
export class AuthenticationService {
    static UNKNOWN_USER = new AuthInfo(null);
    public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(AuthenticationService.UNKNOWN_USER);

    public environment: any = {
        client_id: '',
        client_secret: ''
    };

    constructor(private http: HttpClient) {

    }

    getObservable(): BehaviorSubject<any> {
        return this.authInfo$;
    }

    public get_order(orderid): Promise<any> {
        const token = localStorage.getItem('access_token');
        return this.http.get('https://store.edible16.org.uk/api/order/' + orderid + '?token=' + token).toPromise();
    }

    public undo_item(item): Promise<any> {
        const token = localStorage.getItem('access_token');
        return this.http.delete('https://store.edible16.org.uk/api/order/' + item.order_id + '/item/' + item.id + '?token=' + token, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).toPromise();
    }

    public printOrder(id, type): Promise<any> {
        const full = (type == 1) ? 'full=1&' : '';
        const token = localStorage.getItem('access_token');
        return this.http.get('https://store.edible16.org.uk/api/order/' + id + '/labelPrint?' + full + 'token=' + token, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).toPromise();
    }

    public confirm_item(item): Promise<any> {
        const token = localStorage.getItem('access_token');
        return this.http.post('https://store.edible16.org.uk/api/order/' + item.order_id + '/item/' + item.id + '?token=' + token, {
            item_id: item.id,
            quantity: item.quantity
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).toPromise();
    }

    public markOrderAsOnRoute(order): Promise<any> {
        const token = localStorage.getItem('access_token');
        return this.http.get('https://store.edible16.org.uk/api/order/' + order.id + '/onroute?token=' + token).toPromise();

    }


    public markOrderAsDelivered(order): Promise<any> {

        let note = '';
        const token = localStorage.getItem('access_token');
        if (order.DriverNote) {
            note = '&DriverNote=' + order.DriverNote;
        }
        return this.http.get('https://store.edible16.org.uk/api/order/' + order.id + '/delivered?token=' + token + note).toPromise();

    }

    public markOrder(type, order): Promise<any> {
        const token = localStorage.getItem('access_token');
        return this.http.get('https://store.edible16.org.uk/api/order/' + order.id + '/' + type + '?token=' + token).toPromise();
    }


    public get_due_delivery(): Promise<any> {

        const token = localStorage.getItem('access_token');

        return this.http.get('https://store.edible16.org.uk/api/due_delivery?token=' + token).toPromise();

    }

    public get_custom_filters(data, status): Promise<any> {

        const token = localStorage.getItem('access_token');

        return this.http.post('https://store.edible16.org.uk/api/custom_filters?status=' + status + '&token=' + token,
            {items: data}).toPromise();

    }

    public get_deliveries_filters(data): Promise<any> {

        const token = localStorage.getItem('access_token');

        return this.http.post('https://store.edible16.org.uk/api/deliveries_filter?token=' + token, {items: data}).toPromise();

    }


    public get_deliveries(): Promise<any> {

        const token = localStorage.getItem('access_token');

        return this.http.get('https://store.edible16.org.uk/api/deliveries?token=' + token).toPromise();

    }

    public get_user_details(): Promise<any> {

        const token = localStorage.getItem('access_token');

        return this.http.get('https://store.edible16.org.uk/api/userdetails?token=' + token).toPromise();

    }

    public login(username: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            const formData: FormData = new FormData();
            formData.append('username', username);
            formData.append('password', password);
            formData.append('client_id', '' + this.environment.client_id + '');
            formData.append('client_secret', this.environment.client_secret);
            formData.append('grant_type', 'password');

            let params = new HttpParams();

            params = params.set('client_id', this.environment.client_id);
            params = params.set('client_secret', this.environment.client_secret);
            params = params.set('grant_type', 'password');
            params = params.set('username', username);
            params = params.set('password', password);

            const options = {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            };


            this.http.post('https://store.edible16.org.uk/api/token', params, options).subscribe(
                (res: any) => {

                    const authUser = new AuthInfo(username);
                    authUser.setToken(res.token);
                    authUser.saveToStorage();

                    const user = this.get_user_details();
                    if (user) {
                        this.authInfo$.next(authUser);
                        resolve(res);
                    } else {
                        this.authInfo$.next(AuthenticationService.UNKNOWN_USER);
                        reject(`login failed`);
                    }
                },
                (err) => reject(err)
            );

        });
    }

    public async checkLogin(): Promise<boolean> {
        const token = await localStorage.getItem('access_token');
        console.log(token === null);
        if (typeof token === 'undefined' || token === null || token === '') {
            return false;
        } else {
            const user = await this.get_user_details();
            return (user);
        }
    }


    public logout() {
        this.authInfo$.next(AuthenticationService.UNKNOWN_USER);
    }

}
