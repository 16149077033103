import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterSearch'
})
export class FilterSearchPipe implements PipeTransform {

    transform(value: any, field: string) {
        if (field !== undefined && field.length >= 2) {
            field = field.toLowerCase();
            return value.filter(it => {
                console.log(it);
                const name = it.DeliveryFirstName + ' ' + it.DeliveryLastName;
                const address = it.DeliveryAddressLine1 + ', ' + it.DeliveryAddressLine2 + ', ' + it.DeliveryCity + ', ' + it.DeliveryPostcode;
                const email = it.DeliveryEmail != null  ? it.DeliveryEmail  : '';
                const mobile = it.DeliveryMobileNumber != null  ? it.DeliveryMobileNumber  : '';
                return name.toLowerCase().includes(field) || address.toLowerCase().includes(field) ||
                    email.toLowerCase().includes(field) ||
                    mobile.toLowerCase().includes(field);
            });
        } else {
            return value;
        }
    }

}
