import {Component, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
    selector: 'app-picking-component',
    templateUrl: './picking-component.component.html',
    styleUrls: ['./picking-component.component.scss'],
})
export class PickingComponentComponent implements OnInit {


    public items: any = {
        milk: false,
        eggs: false,
        ice: false,
        farrenheight: false,
        bread: false
    };

    constructor(public popoverCtrl: PopoverController) {
    }

    ngOnInit() {
        let items = localStorage.getItem('pickingItems');
        console.log(items);
        if (items != null) {
            this.items = JSON.parse(items);
        }
    }

    submitFilter() {
        localStorage.setItem('pickingItems', JSON.stringify(this.items));

        this.popoverCtrl.dismiss(this.items);

    }

}
